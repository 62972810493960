import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatNumbersService {

  constructor() { }
  formatNum(num){
    if(num){
      return Number(num).toLocaleString('en-US',{minimumFractionDigits:2, maximumFractionDigits:2});
    }else{
      return ''
    }
  }
  numberOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxNumToWordsService } from "ngx-num-to-words";
import Swal from "sweetalert2";
import { environment } from "../../../environments/environment";
import { AppService } from "../../services/helpers/app.service";
import { EventsService } from "../../services/helpers/events.service";
import { FormatNumbersService } from "../../services/helpers/format-numbers.service";
import { HelperService } from "../../services/helpers/helper.service";

@Component({
  selector: "ngx-receipt",
  templateUrl: "./receipt.component.html",
  styleUrls: ["./receipt.component.scss"],
})
export class ReceiptComponent implements OnInit {
  id: string;
  data: any;
  numberInWords = "";
  numberInWordsar = "";
  lang;
  show: boolean;
  unitType: any;
  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  date
  receipt
  constructor(
    private appservice: AppService,
    private ngxNumToWordsService: NgxNumToWordsService,
    private avRouter: ActivatedRoute,
    private helper: HelperService,
    private eventsService: EventsService,
    private router: Router,
    public formate:FormatNumbersService,
    private tranlateService: TranslateService
  ) {
    document.body.style.overflow = "auto";
    this.eventsService.changeLangObser.subscribe((res) => {
      this.lang = res;
    });
    if (this.avRouter.snapshot.params["id"]) {
      this.id = this.avRouter.snapshot.params["id"];
    }
  }

  ngOnInit(): void {
    if (this.id !== undefined) {
      this.helper.showSpinner();
      this.appservice.GET("invoices/byId/" + this.id).subscribe(
        (resp) => {
          this.data = resp;
          this.date = new Date(this.data.receipts[0]?.date_of_payment)
          if(this.lang=='en'){
            this.date=this.date.toLocaleDateString('en-EG', this.options)
          }else{
            this.date=this.date.toLocaleDateString('ar-EG', this.options)
          }
          this.postinvoice();
          let today: any = new Date();
          const dd = String(today.getDate()).padStart(2, "0");
          const mm = String(today.getMonth() + 1).padStart(2, "0");
          const yyyy = today.getFullYear();

          today = yyyy + "-" + mm + "-" + dd;
          if (today > resp?.date_to) {
            this.show = false;
          } else {
            this.show = true;
          }
          this.numberInWords = this.ngxNumToWordsService.inWords(
            resp.amount_total,
            "en"
          );
          this.numberInWordsar = this.ngxNumToWordsService.inWords(
            resp.amount_total,
            "ar"
          );
          if (this.data.contract !== null) {
            if (this.data.contract?.unit?.type_id === "1") {
              this.unitType = "Apartment";
            } else if (this.data.contract?.unit.type_id === "2") {
              this.unitType = "Villa";
            } else if (this.data.contract.unit.type_id === "3") {
              this.unitType = "Office";
            } else if (this.data.contract.unit.type_id === "4") {
              this.unitType = "Retail";
            }
          }
         this.receipt=this.data.receipts[0];

          this.helper.hideSpinner();
        },
        (err) => {
          this.helper.hideSpinner();
          this.helper.showToastDanger(
            "Error",
            err.error.message[Object.keys(err.error.message)[0]][0]
          );
        }
      );
    }
  }
calcTotal(...values){
  let total = 0
  for (let index = 0; index < values.length; index++) {
    total += Number(values[index])
  }
  return total
}
copyLink(){
  Swal.fire({
    title: this.tranlateService.instant("Do You want to share this link ?"),
    showCancelButton: true,
    confirmButtonText: this.tranlateService.instant('Yes'),
    cancelButtonText: this.tranlateService.instant('No')
  }).then((result) => {
    if (result.isConfirmed) {
      let url = window.location.href
     navigator.clipboard.writeText(url)
      Swal.fire(this.tranlateService.instant('Link has been copied!'), '', 'success')
    }
  })
}
  print() {
    this.router.navigate(["print", this.id]);
  }

  exportFile() {
    const slug = "invoices/saveInvoice/" + this.id;
    this.appservice.openUrl(slug);
  }

  pdfFile() {
    const slug = "invoices/saveInvoicePDF/" + this.id;
    this.appservice.openUrl(slug);
  }

  toggleMenu() {
    document.getElementById("dropdownmenu")?.classList.toggle("show-drop-down");
  }
  sendUrl() {
    this.helper.showSpinner();
    const id = this.id;
    this.appservice.GET(`invoices/sendURL/${id}`).subscribe(
      (res) => {
        this.helper.hideSpinner();
        this.helper.showToastSuccess("Success", "Invoice is sent to Payer");
      },
      (err) => {
        this.helper.hideSpinner();
        this.helper.showToastDanger(
          "Error",
          err.error.message[Object.keys(err.error.message)[0]][0]
        );
      }
    );
  }
  floatNum(item: any) {
    return parseFloat(item).toFixed(2);
  }
  postinvoice() {
    const invoiceObj = {};
    if (this.data.status !== "2") {
      invoiceObj[this.id] = this.data.contract.amount - this.data.discount;
      const sentObj = {
        payment_method: 1,
        invoices: invoiceObj,
        user_id: localStorage.getItem("userId"),
      };
      this.appservice.POST(`receipt`, sentObj).subscribe(
        () => {
          this.helper.showToastSuccess(
            "Thanks for using Boyot",
            "Payment successfully"
          );
          window.location.href = environment.URL;
        },
        (err) => {
          this.helper.hideSpinner();
          this.helper.showToastDanger(
            "Error",
            err.error.message[Object.keys(err.error.message)[0]][0]
          );
        }
      );
    }
  }
  calcAmount(amount,commission,fees){
    let newamount= amount - (commission-fees)
    return newamount
  }
}

import {
  ExtraOptions,
  PreloadAllModules,
  Router,
  RouterModule,
  Routes,
} from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "./services/auth/auth.guard";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { PayerInfoComponent } from "./pages/payer-info/payer-info.component";
import { PrintInvoiceComponent } from "./pages/invoices/print-invoice/print-invoice.component";
import { NBEComponent } from "./pages/NBE/NBE.component";
import { ReceiptComponent } from "./pages/receipt/receipt.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { FawryReferenceNumbrComponent } from "./pages/checkout/fawry-reference-numbr/fawry-reference-numbr.component";
import { NotFoundComponent } from "./pages/miscellaneous/not-found/not-found.component";
export const routes: Routes = [
  {
    path: "pages",
    loadChildren: () =>
    import("./pages/pages.module").then((m) => m.PagesModule),
    data:{preload:true,breadcrumb:""},
    canActivate: [AuthGuard],
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: ":id",
    component: CheckoutComponent,
  },
  {
    path: "fawry/:id",
    component: FawryReferenceNumbrComponent,
  },
  {
    path: "print/:id",
    component: PrintInvoiceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "payer-info/:price/:invoiceId",
    component: PayerInfoComponent,
  },
  {
    path: "NBE/success/:invoiceId/:price",
    component: NBEComponent,
  },
  {
    path: "receipt/:id",
    component: ReceiptComponent,
    data: { breadcrumb: "" },
  },
  {
    path: "",
    redirectTo: "auth/login",
    pathMatch: "full",
  },
  {path: '404/not-found', component: NotFoundComponent},
 {path: '**', redirectTo: '/404/not-found'}
];
export const routesAuthenticated: Routes = [
  {
    path: "pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
      canActivate: [AuthGuard],
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: ":id",
    component: CheckoutComponent,
  },
  {
    path: "print/:id",
    component: PrintInvoiceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "payer-info/:price/:invoiceId",
    component: PayerInfoComponent,
  },
  {
    path: "NBE/success/:invoiceId/:price",
    component: NBEComponent,
  },
  {
    path: "receipt/:id",
    component: ReceiptComponent,
    data: { breadcrumb: "" },
  },
  {
    path: "",
    redirectTo: "pages",
    pathMatch: "full",
  },
  {path: '404/not-found', component: NotFoundComponent},
 {path: '**', redirectTo: '/404/not-found'}
];

const config: ExtraOptions = {
  useHash: false,
  preloadingStrategy: PreloadAllModules,
};

@NgModule({
  imports: [RouterModule.forRoot(routes,config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    if (localStorage.getItem("userId") && localStorage.getItem("user_type")) {
      routes[9] = {
        path: "",
        redirectTo: "pages",
        pathMatch: "full",
      };
      this.router.resetConfig(routes);
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "../../../services/helpers/app.service";
import { NgxNumToWordsService } from "ngx-num-to-words";
import { Location } from "@angular/common";
import { EventsService } from "../../../services/helpers/events.service";
import { HelperService } from "../../../services/helpers/helper.service";
import { FormatNumbersService } from "../../../services/helpers/format-numbers.service";

@Component({
  selector: "ngx-print-invoice",
  templateUrl: "./print-invoice.component.html",
  styleUrls: ["./print-invoice.component.scss"],
})
export class PrintInvoiceComponent implements OnInit {
  id: string;
  data: any;
  numberInWords = "";
  numberInWordsar = "";
  lang;
  unitType: any;
  date = new Date('1/12/2023')
  constructor(
    private appservice: AppService,
    private ngxNumToWordsService: NgxNumToWordsService,
    private avRouter: ActivatedRoute,
    private helper: HelperService,
    private eventsService: EventsService,
    private location: Location,
    public formate:FormatNumbersService
  ) {
    this.eventsService.changeLangObser.subscribe((res) => {
      this.lang = res;
    });
    if (this.avRouter.snapshot.params["id"]) {
      this.id = this.avRouter.snapshot.params["id"];
    }
  }

  ngOnInit(): void {
    if (this.id !== undefined) {
      this.appservice.GET("invoices/byId/" + this.id).subscribe(
        (resp) => {
          this.data = resp;
          this.numberInWords = this.ngxNumToWordsService.inWords(
            resp.amount_total,
            "en"
          );
          this.numberInWordsar = this.ngxNumToWordsService.inWords(
            resp.amount_total,
            "ar"
          );
          setTimeout(() => {
            window.print();
          }, 100);

          window.onafterprint = () => {
            window.history.back();
          };
          if(this.data.contract.unit.type_id ==='1'){
            this.unitType='Apartment'
          }else if(this.data.contract.unit.type_id ==='2'){
            this.unitType='Villa'

        }else if(this.data.contract.unit.type_id ==='3'){
          this.unitType='Office'
        }else if(this.data.contract.unit.type_id ==='4'){
          this.unitType='Retail'
        }

      },
        (err) => {
          this.helper.hideSpinner();
          this.helper.showToastDanger(
            "Error",
            err.error.message[Object.keys(err.error.message)[0]][0]
          );
        }
      );
    }
  }
  calcTotal(...values){
    let total = 0
    for (let index = 0; index < values.length; index++) {
      total += Number(values[index])
    }
    return total
  }
  print() {
    window.print();
  }

  exportFile() {
    const slug = "invoices/saveInvoice/" + this.id;
    this.appservice.openUrl(slug);
  }

  pdfFile() {
    const slug = "invoices/saveInvoicePDF/" + this.id;
    this.appservice.openUrl(slug);
  }

  toggleMenu() {
    document.getElementById("dropdownmenu")?.classList.toggle("show-drop-down");
  }
  sendUrl() {
    this.helper.showSpinner();
    const id = this.id;
    this.appservice.GET(`invoices/sendURL/${id}`).subscribe(
      (res) => {
        this.helper.hideSpinner();
        this.helper.showToastSuccess("Success", "Mail sent to you");
      },
      (err) => {
        this.helper.hideSpinner();
        this.helper.showToastDanger(
          "Error",
          err.error.message[Object.keys(err.error.message)[0]][0]
        );
      }
    );
  }
  floatNum(item: any) {
    return parseFloat(item).toFixed(2);
  }
}

<div class="d-flex justify-content-center">
  <!-- <div *ngIf="data?.receiver?.logo" class="d-flex justify-content-center">
    <img class="logo" [src]="data?.receiver?.logo" alt="" />
  </div>
  <div *ngIf="!data?.receiver?.logo" class="d-flex justify-content-center">
    <img class="w-25" src="assets/images/Logo english - property payment0.png">
  </div> -->
  <!-- <div class=" tax col-lg-6 mb-md-3 head-text d-flex justify-content-center align-items-center">
    <p>{{ "Tax Registration Number "  | translate }}</p>
    <p> &nbsp; {{'('+ '262-034-573'+ ')'}}</p>
  </div> -->
</div>
<div class="container pt-5">
  <div class="row">
    <div class="bg-white width-80-center">
      <div class="col-md-10 offset-md-1 receipt">
        <div>
          <div class="d-flex justify-content-between pt-3">
            <div>
              <p class="f-700">{{"Payment Receipt" | translate}}</p>
            </div>
            <div>
              <p class="padge"> {{"Successful Process" | translate}}</p>
            </div>
          </div>
          <div class="container">
           <P [ngClass]="lang=='en'?'f-left':'f-right'" >
             {{'Your Process No. ' | translate}}
             <span style="color:#CC9900;"> {{'#'}}{{data?.invoice_log['0']?.invoice_short_uuid}}</span>
             {{'Succesfully Completed on' |translate}}
             <span *ngIf="lang=='ar'">{{this.date.toLocaleDateString('ar-EG', this.options)}}</span>
             <span *ngIf="lang=='en'">{{this.date.toLocaleDateString('en-EG', this.options)}}</span>
            </P>

           <div class=" pt-3 dir">
            <div>
              <p class="f-700">{{"Service Type" | translate}} </p>
              <p> {{"Type"}}</p>
            </div>
            <div>
              <p class="f-700">{{"Subscriper" | translate}}</p>
              <p >{{data?.payer?.name}}</p>
            </div>
          </div>
          <div class="pt-3 dir">
            <div>
              <p class="f-700">{{"Unit Name" | translate}} </p>
              <p>{{data?.contract?.unit?.name}}</p>
            </div>
            <div>
              <p class="f-700">{{"Unit Number" | translate}}</p>
              <p >{{data?.contract?.unit?.unit_no}}</p>
            </div>
          </div>

          </div>
        <div class="text-center">
          <p>{{"Payment Details" | translate}}</p>
          <hr>
        </div>
        <div class="d-flex justify-content-between pt-3">
          <div>
            <p class="f-700 text-center">{{"Description" | translate}} </p>
            <div>
              {{"Service Amount" |translate}}
            </div>
          </div>
          <div>
            <p class="f-700 text-center">{{"Amount" | translate}}</p>
            <p> {{ formate.formatNum(data?.amount_paid) | translate}} {{ "EGP" | translate }}</p>
          </div>
        </div>
        <hr>
        <div class="d-flex justify-content-between pt-3">
          <div>
            <p class="f-700">{{"Total Amount" | translate}} </p>
          </div>
          <div>
            <p class="f-700"> {{ formate.formatNum(data?.amount_paid) | translate}} {{ "EGP" | translate }}</p>
          </div>
        </div>
        </div>
      <!-- <div class="text-center">
        <p>{{"Extra Details" | translate}}</p>
        <hr>
      </div>
      <div class="d-flex justify-content-between pt-3">
        <div class="col-6 text-black mid-font pl-4 d-flex
        align-items-center">
          <p>{{ "Subscriper" | translate }}</p>
        </div>
        <div class="col-6 text-black details text-right pr-5">
          <p>{{'Name' | translate}}: <span >{{data?.payer?.name}}</span></p>
          <p>{{'Mobile' | translate}}: <span>{{data?.payer?.tel1}}</span></p>
        </div>
      </div>
      <div class="d-flex justify-content-between pt-3">
        <div class="col-6 text-black mid-font pl-4 d-flex
        align-items-center">
          <p>{{ "Company" | translate }}</p>
        </div>
        <div class="col-6 text-black details text-right pr-5">
          <p>{{'Mobile' | translate}}: <span>{{data?.receiver?.tel1}}</span></p>
        </div>
      </div> -->
        <!-- <div class="row">
          <div class="col-md-12 righttext-lang">
            <div class="mt-3">
              <button class="btn p-5-45 white-color button-bk" type="button" id="dropdownMenuButton1"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ "Print" | translate }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <a class="dropdown-item fs-15 color-black text-decoration-none" (click)="print()">{{ "Print" | translate
                  }}</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item fs-15 color-black text-decoration-none" (click)="pdfFile()">{{ "EXPORT TO PDF" |
                  translate }}</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item fs-15 color-black pointer text-decoration-none" (click)="exportFile()">
                  {{ "EXPORT TO EXCEL" | translate }}
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-12 head-text d-flex align-items-start justify-content-center mt-4">
            <p>{{ "Thank you for using Boyot" | translate }} !</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 head-text d-flex justify-content-center">
            <p>{{ "Old rent payment receipt" | translate }}</p>
          </div>
        </div>
        <div class="date w-100 text-center mt-3">
          <p>{{'12/1/2022' | date:'EEEE, MMMM d, y, h:mm:ss a'}}</p>
        </div>

        <hr>
        <div class="container">
          <div class="d-flex">
            <p class="col-gray">{{ "Payment Summary" | translate }} :</p>
          </div>
          <hr>
          <div class="row">
            <div class="col-6 text-black mid-font pl-4">
             <p>{{ "Subtotal" | translate }}</p>
            </div>
            <div class="col-6 text-black mid-font text-right pr-5">
              {{ formate.formatNum(data?.amount_paid) }} {{ "EGP" | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-black mid-font pl-4">
             <p>{{ "Added Taxes" | translate }}</p>
            </div>
            <div class="col-6 text-black mid-font text-right pr-5">
              {{ formate.formatNum(data?.taxes) }} {{ "EGP" | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-black mid-font pl-4">
             <p>{{ "Discount" | translate }}</p>
            </div>
            <div class="col-6 text-black mid-font text-right pr-5">
              {{ formate.formatNum(data?.discount) }} {{ "EGP" | translate }}
            </div>
          </div>
          <hr style="background-color: #000; height: 0px; margin-bottom: 0;">
          <div class="row">
            <div class="col-6 text-black mid-font pl-4">
              <p>{{ "Total" | translate }}</p>
            </div>
            <div class="col-6 text-black mid-font text-right pr-5 total">
              {{formate.formatNum(calcTotal(data?.amount_paid,data?.taxes ,data?.discount))}} {{ "EGP" | translate }}
            </div>
          </div>
          <hr style="background-color: #000; height: 0px; margin-top: 0;">
        </div>
        <div class="mt-4">
          <div class="d-flex">
            <p class="col-gray">{{ "More Receipt Details" | translate }} :</p>
          </div>
          <hr>
          <div class="w-100">
            <div class="row">
              <div class="col-6 text-black mid-font pl-4 d-flex
              align-items-center">
                <p>{{ "Company" | translate }}</p>
              </div>
              <div class="col-6 text-black details text-right pr-5">
                <p>{{'Name' | translate}}: <span >{{data?.receiver?.name}}</span></p>
                <p>{{'Mobile' | translate}}: <span>{{data?.receiver?.tel1}}</span></p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-6 text-black mid-font pl-4 d-flex
              align-items-center">
                <p>{{ "Tenant" | translate }}</p>
              </div>
              <div class="col-6 text-black details text-right pr-5">
                <p>{{'Name' | translate}}: <span >{{data?.payer?.name}}</span></p>
                <p>{{'Mobile' | translate}}: <span>{{data?.payer?.tel1}}</span></p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-6 text-black mid-font pl-4 d-flex
              align-items-center">
                <p>{{ "Unit" | translate }}</p>
              </div>
              <div class="col-6 text-black details text-right pr-5">
                <p>{{'Name' | translate}}: <span >{{data?.contract?.unit?.name}}</span></p>
                <p>{{'Type' | translate}}: <span>{{unitType}}</span></p>
                <p>{{'Number' | translate}}: <span>{{data?.contract?.unit?.unit_no}}</span></p>
                <p>{{'Floor' | translate}}: <span>{{data?.contract?.unit?.floor_no}}</span></p>

              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-6 text-black mid-font pl-4 d-flex
              align-items-center">
                <p>{{ "Invoice" | translate }}</p>
              </div>
              <div class="col-6 text-black details text-right pr-5">
                <p>{{'Name' | translate}}: <span >{{data?.name}}</span></p>
                <p>{{'Type' | translate}}: <span>{{data?.invoice_type?.name}}</span></p>
                <p>{{'Number' | translate}}: <span>{{'#'}}{{data?.invoice_log['0']?.invoice_short_uuid}}</span></p>
                <p>{{'Floor' | translate}}: <span>{{data?.contract?.unit?.floor_no}}</span></p>
                <p>{{'Area Code' | translate}}: <span>{{data?.contract?.unit?.address?.postal_code}}</span></p>

              </div>
            </div>
          </div>
          <hr>

        </div>
        <div class="row justify-content-center">
          <div class="col-md-10 head-text">
            <p class="note invoice-font font-weight-normal color-black arlineheight">
              {{
              "This invoice is only considered within the limits of the value and the period mentioned in it, and the
              collector is not responsible for any dispute that arises regarding the rent collected between the lessee
              and the owner." | translate
              }}
            </p>
          </div>
        </div>
        <br /><br /> -->
        <div class="thankyou">
          <p class="text-center">
            <img class="pt-5" src="../../../../assets/images/logo+powerd.svg" />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "../../services/helpers/app.service";
import { EventsService } from "../../services/helpers/events.service";
import { HelperService } from "../../services/helpers/helper.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";

@Component({
  selector: "ngx-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
  lang: string;
  id: any;
  invoices: any = [];
  total_amount: any = "0.00";
  total_discount: any = "0.00";
  total: any = "0.00";
  end_date;
  payment_method: string = null;
  partial_amount:any;
  partialChecked:any;
  partialForm:FormGroup
  msgErr:boolean =true
  invoice_type_check:boolean
  total_invoice_amount:any = "0.00"
  invoicesChecked: any =[]
  invoiceID:any
  fees_amount:any=[]
  total_fees:any
  payTotal;
  showInvices:boolean=false;
  partialCheckedShow:boolean=false
  valueCheck
  updateInvoiceID:any;
  card_active:boolean=false
  activeArray:any[]=[];
  amoutBeforDiscount:any=0
  fawry:any
  invoicesCount=0
  credintialsObj = {
    merchant_reference_id: "123456",
    customer_merchant_profile_id: "13",
    customer_name: "John Doe",
    customer_email: "example@gmail.com",
    customer_mobile: "01129365372",
    amount: "",
    signature: "",
    description: "Charge request description",
  };
  getUrlData: any;
  install_value: any;
  payment_integration: any;
  payer_id:any
  allInvoices:any[]
  constructor(
    private avroute: ActivatedRoute,
    private helper: HelperService,
    private appService: AppService,
    private eventsService: EventsService,
    public tranlateService: TranslateService,
    private router: Router,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.partialForm=this.formBuilder.group({
      partial_amount:['',[Validators.pattern("^([1-9][0-9]*)$")]]
    })
    this.id = this.avroute.snapshot.params["id"];
    this.getPaymentIntegration();
    this.getURL();
    this.eventsService.changeLangObser.subscribe((res) => {
      this.lang = res;
    });
  }
  onChangeShow(event){
   this.partialChecked= event.target.checked;
   this.invoices.forEach((invoice) => {
     this.total_invoice_amount = invoice.amount
   if(!event.target.checked){
      this.partial_amount=null
      this.msgErr=true
      this.total=invoice.amount
      this.getfees()
      }
      this.total_discount=this.moneyFormatter(invoice.discount)
      this.total_amount=this.moneyFormatter(invoice.amount)
      this.total=this.moneyFormatter(this.total_amount)
    })
  }
  selectAll(event){
    if(event.target.checked){
      this.activeArray.forEach((c) =>{
        c.checked = event.target.checked
        c.active = event.target.checked
      })
      this.allInvoices.forEach((invoice)=>{
        this.total_amount += Number(invoice.amount_remaining)
        this.total_invoice_amount= this.total_amount
        this.total = Number(this.total_amount)
        this.total_discount += Number(invoice?.contract?.discount)
        this.amoutBeforDiscount +=(Number(invoice.amount_remaining) +Number(invoice?.contract?.discount))
      })
      this.getfees()
    } else if(!event.target.checked){
      this.activeArray.forEach((c) => {
        c.checked = event.target.checked
           if(c.index!=0){
            c.active = event.target.checked
           }
      }
      )
      this.total_amount = 0
      this.total_invoice_amount= 0
      this.total = 0
      this.total_discount = 0
      this.amoutBeforDiscount = 0
      this.total_fees =0
    }
  }
  onChangePartial(event,value){
    this.total_invoice_amount=0
    this.valueCheck=value;
    this.partialCheckedShow=event.target.checked
    let newValue = 0
    this.invoicesCheckedData.forEach((invoice)=>{
        if(event.target.checked){
        this.total_invoice_amount +=Number(invoice?.amount_remaining)
        this.updateInvoiceID=invoice.id
        }else{
          this.partialForm.get('partial_amount').setValue(0)
          newValue += Number(invoice?.amount_remaining)
          this.total =newValue
          this.total_amount = newValue
          this.getfees()
        }

      })

  }
  lastPartialChange(event,invoiceData){
    let partial_amount=this.partialForm.get('partial_amount').value
    let newtotal_amount = 0;
    let newtotal_discount = 0;
    this.invoicesCheckedData.forEach(invoice => {
      newtotal_amount += Number(invoice.amount_remaining)
      newtotal_discount += Number(invoice.discount)
    });
    if(partial_amount) {
      newtotal_amount -=this.invoicesCheckedData[this.invoicesCheckedData.length-1].amount_remaining
      newtotal_amount +=partial_amount
    }
    this.total_amount = this.moneyFormatter(newtotal_amount);
    // this.total_discount = this.moneyFormatter(newtotal_discount);
    this.total = this.moneyFormatter(newtotal_amount );
    this.getfees()
  }
  partialChange(event,invoice){
    this.invoices.forEach((invoice) => {
      if(this.partialForm.get('partial_amount').value >= invoice.amount ){
        this.msgErr=false
      }else{
        this.msgErr=true
      }
    })
    this.updateInvoiceID=invoice.id
    this.getTotalAmount()
  }
  getfees(){
    this.helper.showSpinner()
    this.appService.POST(`receipt_getfees`,{invoice_id: this.invoiceID,amount: this.total}).subscribe((res)=>{
     this.fees_amount=res.commission_and_fees
     this.total_fees=this.fees_amount.fees
      this.helper.hideSpinner()
    })
  }
  getTotalAmount(){
    let partial_amount=this.partialForm.get('partial_amount').value
    let newtotal_amount = 0;
    let newtotal_discount = 0;
      this.invoices.forEach((invoice) => {
        this.invoiceID=invoice.id
        this.total_invoice_amount=invoice.amount
          if(partial_amount){
            newtotal_amount += partial_amount;
            newtotal_discount += invoice.discount;
          }else{
            newtotal_amount += invoice.amount;
            newtotal_discount += invoice.discount;
          }
          this.total_discount=this.moneyFormatter(Number(invoice.discount))
          this.total_amount=this.moneyFormatter(Number(invoice.amount))
          this.amoutBeforDiscount= this.moneyFormatter(Number(this.total_discount) +Number(this.total_amount))
          this.total=this.moneyFormatter(this.total_amount-this.total_discount)
      });


    this.total_amount = this.moneyFormatter(newtotal_amount);
    // this.total_discount = this.moneyFormatter(newtotal_discount);
    this.total = this.moneyFormatter(newtotal_amount );
    this.getfees()
    if (Number(this.total_amount) < Number(this.total_discount)) {
      Swal.fire({
        title: this.tranlateService.instant("The discount amount should be less the the totla amount"),
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.tranlateService.instant("back")
      }).then(() => {
        if (localStorage.getItem('user_type') == '2') {
          this.router.navigate(['/pages/dashboard/payer']);
        } else if (localStorage.getItem('user_type')) {
          this.router.navigate(['/pages/dashboard/super']);
        } else {
          this.router.navigate(['/auth/login']);
        }
      })
    }
  }
  getURL() {
    this.helper.showSpinner();
    this.appService.GET(`invoices/getUrlData/${this.id}`).subscribe(
      (res: any) => {
        this.getUrlData = res;
        this.invoices = this.getInvoices(res);
        this.end_date = res?.invoice?.date_to;
        this.payer_id=res.invoice.payer.id
        this.getInvoicesNumber(this.payer_id)
        this.helper.hideSpinner();
        this.getTotalAmount()
      },
      (err) => {
        this.helper.hideSpinner();
        this.helper.showToastDanger("error", err.error.message);
      }
    );
  }
  getPaymentIntegration() {
    this.appService.GET(`payment/method`).subscribe(
      (res: any) => {
        if (res.Payment_Gateway === "NBE") {
          this.payment_integration = "nbeCheckout";
        } else if (res.Payment_Gateway === "CAE") {
          this.payment_integration = "caeCheckout";
        } else {
          this.payment_integration = "nbeCheckout";
        }
      },
      (err) => {
        this.helper.showToastDanger("error", err.error.message);
      }
    );
  }
  moneyFormatter(num: any) {
    return parseFloat(num).toFixed(2);
  }
  getCType(num) {
    if (num === "1") {
      return "Rent";
    } else if (num === "2") {
      return "Maintenance";
    } else {
      return "Installment";
    }
  }
  dateFormatter(s: string) {
    let date = s.split("-");
    return date.join("/");
  }
  getInvoices(response) {
    let results: any = [];
    if (typeof response === "object") {
      if(response?.invoice?.contract){
        results.push({
          id:response?.invoice?.id,
          unit_name: response?.invoice?.contract?.unit?.name,
          contract_type: this.getCType(
            response?.invoice?.contract?.contract_type_id
          ),
          amount: this.moneyFormatter(Number(response?.invoice.amount_total)),
          amount_remaining:this.moneyFormatter(Number(response?.invoice?.amount_remaining)),
          discount: this.moneyFormatter(Number(response?.invoice?.discount)),
          owner: response?.invoice?.receiver?.name,
          start_date: this.dateFormatter(response?.invoice?.date_from),
          end_date: this.dateFormatter(response?.invoice?.date_to),
          isChecked: false,
          status: response?.invoice?.status,
          contract_name:response?.invoice?.contract?.contract_name
        });
      }else{
        results.push({
          id:response?.invoice?.id,
          amount: this.moneyFormatter(Number(response?.invoice.amount_total)),
          amount_remaining:this.moneyFormatter(Number(response?.invoice?.amount_remaining)),
          discount: this.moneyFormatter(Number(response?.invoice?.discount)),
          owner: response?.invoice?.receiver?.name,
          start_date: this.dateFormatter(response?.invoice?.date_from),
          end_date: this.dateFormatter(response?.invoice?.date_to),
          isChecked: false,
          status: response?.invoice?.status,
        });
      }
    } else {
      response.forEach((res) => {
        results.push({
          unit_name: res?.invoice?.contract?.unit?.name,
          contract_type: this.getCType(
            res?.invoices?.contract?.contract_type_id
          ),
          amount: this.moneyFormatter(Number(res?.invoice.amount_total)),
          amount_remaining:this.moneyFormatter(Number(response?.invoice?.amount_remaining)),
          discount: this.moneyFormatter(Number(res?.invoice?.discount)),
          owner: res?.invoice?.receiver?.name,
          start_date: this.dateFormatter(res?.invoice?.date_to),
          end_date: this.dateFormatter(res?.invoice?.date_from),
          isChecked: false,
          status: res?.invoice?.status,
        });
      });

    }
    return results;
  }
  invoicesCheckedData:any=[]
  onChange(event,index,invoice) {
    this.invoice_type_check=event.target.checked
    if(event.target.checked){
      this.invoicesCheckedData.push(invoice)
      this.invoicesChecked.push(index)
      this.total_amount += Number(invoice.amount_remaining)
      this.total_invoice_amount= this.total_amount
      this.total = Number(this.total_amount)
      this.total_discount += Number(invoice?.contract?.discount)
      this.amoutBeforDiscount +=(Number(invoice.amount_remaining) +Number(invoice?.contract?.discount))
      this.activeArray[index].active=true
    }else{
      this.card_active=false
      this.total_amount -= Number(invoice.amount_remaining)
      this.total_invoice_amount-=Number(invoice.amount_remaining)
      this.total_discount -= Number(invoice?.contract?.discount)
      this.total = this.total_amount
      this.amoutBeforDiscount -=(Number(invoice.amount_remaining) +Number(invoice?.contract?.discount))
      this.invoicesChecked.splice(this.invoicesChecked.indexOf(index),1)
      this.invoicesCheckedData.splice(this.invoicesCheckedData.indexOf(invoice),1)
      this.activeArray[index].active=false
      this.total=0
      this.invoicesCheckedData.forEach((invoice) => {
        this.total +=Number(invoice.amount_remaining)
      })
      this.partialForm.get('partial_amount').setValue(0)
    }
    if(index==1){
      this.getfees()
    }
  }
  past_due(val) {
    var today = new Date();
    var date = new Date(val);
    if (date <= today) return true;
    return false;
  }
  methodChange(method: string) {
    this.payment_method = method;
  }
  submitFawry() {
    this.helper.showSpinner();
      this.appService.POST(`fawryReferenceNumber`, {
            order_id: this.id,
            amount: Number(this.calculateTotal(this.total,this.total_fees)),
          }).subscribe((res) => {
            if(res.statusCode!==9949){
              this.router.navigate([`fawry/${this.id}`,{
                referenceNumber:res.referenceNumber,
                paymentAmount:res.paymentAmount,
                fawryfees:Number(res.fawryFees),
                customerName:res.customerName,
                orderStatus:res.orderStatus}])
            }else{
              Swal.fire({
                title: this.tranlateService.instant(
                  "Request already processed"
                ),
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.tranlateService.instant("Continue"),
              });
            }
            this.helper.hideSpinner();
          }, (err) => {
            this.helper.showToastWarning("Error","Something went wrong")
            this.helper.hideSpinner();
          });

  }
  checkout() {
    if (this.payment_method === "credit") {
      window.location.href =
        `${environment.URL}${this.payment_integration}/?currency=EGP&order_id=` +
        this.id +
        "&amount=" +
         this.calculateTotal(this.total,this.total_fees);
    } else if (this.payment_method === "fawry") {
      this.submitFawry();
    } else if (this.payment_method === "installment") {
      if (
        this.install_value == "" ||
        this.install_value == null ||
        this.install_value == undefined
      ) {
        Swal.fire({
          title: this.tranlateService.instant(
            "You must select installment period"
          ),
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.tranlateService.instant("Continue"),
        });
      } else {
        window.location.href =
          `${environment.URL}${this.payment_integration}/?currency=EGP&order_id=` +
          this.id +
          "&amount=" +
          this.payTotal +
          "&install=" +
          this.install_value;
      }
    }

    // update invoice if using partial payment
    let payment = this.partialForm.get('partial_amount').value
    if(payment){
      this.appService.GET(`getSessionPayment/?invoice_id=${this.updateInvoiceID}&amount=${payment}&gate=${this.payment_method}`).subscribe((res)=>{
      })
    }
  }
  select_installment(value: any) {
    this.install_value = value;
  }
  calculateTotal(amount,fees){
    if(fees===0){
      this.payTotal=Number(amount)
      return this.moneyFormatter( Number(amount))
    }else{
      this.payTotal=Number(amount)+ Number(fees)
      return  this.moneyFormatter(Number(amount)+ Number(fees))
    }
  }
  showAllInvices(){
    this.total_fees=0
    this.total=0
    this.total_discount=0
    this.amoutBeforDiscount=0
    var today = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    let status={}
    this.helper.showSpinner();
    this.showInvices= !this.showInvices;
    if(this.showInvices){
      this.partialChecked=false;
      this.total_amount=0
      this.appService.GET(`report/unpaid/tenant`,{user_id:this.payer_id,date_to:today}).subscribe((res)=>{
        this.allInvoices=res
        this.allInvoices.sort((a,b)=>{
          if (a.date_to > b.date_from) {
            return 1;
          }
          if (a.date_to < b.date_from) {
            return -1;
          }
          return 0;
        });
        for (let index = 0; index < this.allInvoices.length; index++) {
          status={}
          status['index']=index;
          status['active']=false
          status['checked']=false
          if(index===0){
            status['active']=true
          }
          this.activeArray.push(status)
        }
        this.helper.hideSpinner();
      })


    }else{
      this.getURL()
      this.activeArray.forEach((ele)=>{
        ele['index']===0?ele['active']=true:ele['active']=false;
      })
      this.helper.hideSpinner();
    }
  }
  fawryType(event){
  event.target.checked?this.fawry= event.target.value:this.fawry=''
  }
  getInvoicesNumber(id){
    this.helper.showSpinner()
    this.appService.GET(`report/count_unpaid_tenant`,{user_id:id}).subscribe((res)=>{
      this.invoicesCount=res;
    })
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppService } from '../../../services/helpers/app.service';
import { HelperService } from '../../../services/helpers/helper.service';

@Component({
  selector: 'ngx-fawry-reference-numbr',
  templateUrl: './fawry-reference-numbr.component.html',
  styleUrls: ['./fawry-reference-numbr.component.scss']
})
export class FawryReferenceNumbrComponent implements OnInit {
  id:any
  date:any
  data:any
  constructor(
    private router:ActivatedRoute,
    private appService:AppService,
    private helper :HelperService,
    private datePipe: DatePipe
  ) {
   this.id=this.router.snapshot.params["id"]
   this.data=this.router.snapshot.params
  }

  ngOnInit(): void {
    let date = new Date
    this.date=this.datePipe.transform(date.setDate(date.getDate()+2), 'yyyy-MM-dd')
  }

}
